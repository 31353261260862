import { useStyles } from './style'
import ReactECharts from 'echarts-for-react'
import { useTranslation } from 'react-i18next'
import { useAssessment } from './useAssessment'
import { useMediaQuery } from '@mantine/hooks'

export const Assessment = () => {
	const { t } = useTranslation()
	const { classes, cx } = useStyles()
	const { data, option } = useAssessment()
	const largeScreen = useMediaQuery('(min-width: 950px)')

	return (
		<div className={classes.wrapper}>
			<h3 className={classes.title}>{t('your_skin_assessment')}</h3>
			<div className={classes.chart}>
				<ReactECharts
					option={option}
					notMerge={true}
					lazyUpdate={true}
					theme={'theme_name'}
					opts={{ renderer: 'svg' }}
					style={{
						width: largeScreen ? '1000px' : '600px',
						height: largeScreen ? '700px' : '350px',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				/>
				{data.measurements.map((measurement: any, idx: number) => {
					const { badge, title, value } = measurement

					const isStrength = badge === t('skin.strength')
					const isPriority = badge === t('skin.priority')

					let backgroundColor = '#FFFFFF'

					if (isStrength) {
						backgroundColor = '#FFF4F0'
					} else if (isPriority) {
						backgroundColor = '#EEABAC'
					}

					const score = `score${idx + 1}` as keyof typeof classes
					const hint = `hint${idx + 1}` as keyof typeof classes
					const label = `label${idx + 1}` as keyof typeof classes

					return (
						<div key={score}>
							<div className={cx(classes.score, classes[score])} style={{ backgroundColor }}>
								{value}
							</div>

							<div className={cx(classes.label, classes[label])}>
								<span>{title}</span>
								<span
									key={hint}
									className={classes.hint}
									style={{
										background: isStrength ? '#FFF4F0' : '#FFF4F5',
										color: isStrength ? '#7E4430' : '#972E2F',
									}}
								>
									{badge}
								</span>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
