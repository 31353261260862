import { useStyles } from './style'
import { ReactComponent as IconExpire } from 'icons/expire.svg'

export const Expire = () => {
	const { classes } = useStyles()
	return (
		<div className={classes.wrapper}>
			<div className={classes.child}>
				<IconExpire />
				<h1>Oops! Something went wrong</h1>
				<h1>or</h1>
				<h1>Your session has expired</h1>
			</div>
		</div>
	)
}
