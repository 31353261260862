import axios from 'axios'
import { useParamQuery } from 'hooks/useParamQuery'

const oldURL = process.env.REACT_APP_ANALYSIS_BASE_URL
const phase3BaseURL = process.env.REACT_APP_ANALYSIS_PHASE_3_BASE_URL
const token = process.env.REACT_APP_TOKEN as string

const { params } = useParamQuery()
const phase = params.get('phase')

const baseURL = phase === '3' ? phase3BaseURL : oldURL

const analysisRequest = axios.create({
	baseURL,
})

analysisRequest.interceptors.request.use(
	async (config: any) => {
		config.headers = {
			...config.headers,
			Authorization: `Bearer ${token}`,
		}
		return config
	},
	async error => await Promise.reject(error),
)

export { analysisRequest }
