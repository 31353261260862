import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { showNotification, cleanNotifications } from '@mantine/notifications'
import { useInterval } from '@mantine/hooks'

dayjs.extend(utc)

export interface IFooter {
	secondsRemained: number
}

export const useFooter = ({ secondsRemained }: IFooter) => {
	const queryClient = useQueryClient()
	const [hours, setHours] = useState(Math.floor(secondsRemained / 3600))
	const [minutes, setMinutes] = useState(Math.floor(secondsRemained / 60) % 60)
	const [seconds, setSeconds] = useState(secondsRemained % 60)
	const [remainder, setRemainder] = useState(secondsRemained)

	const interval = useInterval(() => {
		setRemainder(s => s - 1)
	}, 1000)

	useEffect(() => {
		interval.start()
		return interval.stop
	}, [])

	useEffect(() => {
		setHours(Math.floor(remainder / 3600))
		setMinutes(Math.floor(remainder / 60) % 60)
		setSeconds(remainder % 60)

		if (remainder === 180) {
			showNotification({
				message: 'You have 3 minutes left',
				title: 'Dedline',
				autoClose: false,
			})
		}

		if (remainder < 1) {
			cleanNotifications()
			queryClient.invalidateQueries(['web-result-cndpskin'])
		}
	}, [remainder])

	return { hours, minutes, seconds }
}
