import i18n from 'i18next'
import en from './locales/en.json'
import ja from './locales/ja.json'
import fr from './locales/fr.json'
import ar from './locales/ar.json'
import cs from './locales/cs.json'
import de from './locales/de.json'
import el from './locales/el.json'
import es from './locales/es.json'
import id from './locales/id.json'
import it from './locales/it.json'
import ko from './locales/ko.json'
import nl from './locales/nl.json'
import pl from './locales/pl.json'
import pt from './locales/pt.json'
import ro from './locales/ro.json'
import ru from './locales/ru.json'
import th from './locales/th.json'
import tr from './locales/tr.json'
import vi from './locales/vi.json'
import zhS from './locales/zh-Hans.json'
import zhT from './locales/zh-Hant.json'
import zhTW from './locales/zh-Hant-TW.json'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
	lng: 'en',
	debug: false,
	fallbackLng: 'en',
	resources: {
		en: {
			translation: en,
		},
		ja: {
			translation: ja,
		},
		fr: {
			translation: fr,
		},
		ar: {
			translation: ar,
		},
		cs: {
			translation: cs,
		},
		de: {
			translation: de,
		},
		el: {
			translation: el,
		},
		es: {
			translation: es,
		},
		id: {
			translation: id,
		},
		it: {
			translation: it,
		},
		ko: {
			translation: ko,
		},
		nl: {
			translation: nl,
		},
		pl: {
			translation: pl,
		},
		pt: {
			translation: pt,
		},
		ro: {
			translation: ro,
		},
		ru: {
			translation: ru,
		},
		th: {
			translation: th,
		},
		tr: {
			translation: tr,
		},
		vi: {
			translation: vi,
		},
		'zh-Hans': {
			translation: zhS,
		},
		'zh-Hant': {
			translation: zhT,
		},
		'zh-Hant-TW': {
			translation: zhTW,
		},
	},
	ns: ['translation'],
	defaultNS: 'translation',
	react: {
		bindI18n: 'languageChanged',
		bindI18nStore: '',
		transEmptyNodeValue: '',
		transSupportBasicHtmlNodes: true,
		transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
		useSuspense: true,
	},
})

export { i18n }
