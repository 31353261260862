import { useStyles } from './style'
import { IFooter, useFooter } from './useFooter'
import { useTranslation } from 'react-i18next'

export const Footer = ({ secondsRemained }: IFooter) => {
	const { t } = useTranslation()
	const { classes } = useStyles()
	const { hours, minutes, seconds } = useFooter({
		secondsRemained,
	})

	return (
		<footer className={classes.wrapper}>
			<div className={classes.box1}>
				<p>{t('disclaimer_1')}</p>
				<p>{t('disclaimer_2')}</p>
				<h5>{`${hours}:${minutes}:${seconds}`}</h5>
			</div>
			<div className={classes.box2}>
				<a
					href='https://www.dior.com/en_int/beauty/parfum-legal-terms'
					target='_blank'
					rel='noreferrer'
				>
					<h6>{t('legal_mentions')}</h6>
				</a>
				<a href='https://www.dior.com/en_int/beauty/faq-parfum' target='_blank' rel='noreferrer'>
					<h6>{t('faq')}</h6>
				</a>
				<a
					href='https://www.dior.com/en_int/beauty/contact-parfum'
					target='_blank'
					rel='noreferrer'
				>
					<h6>{t('contact')}</h6>
				</a>
			</div>
		</footer>
	)
}
