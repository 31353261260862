import axios from 'axios'

const baseURL = process.env.REACT_APP_BASE_URL
const token = process.env.REACT_APP_TOKEN as string

const appRequest = axios.create({
	baseURL,
	headers: {
		Accept: '*/*',
		Connection: 'keep-alive',
		'Content-Type': 'application/json',
	},
})

appRequest.interceptors.request.use(
	async (config: any) => {
		config.headers = {
			...config.headers,
			'X-CHOWIS-CONSULTANT-TOKEN': token,
		}
		return config
	},
	async error => await Promise.reject(error),
)

export { appRequest }
