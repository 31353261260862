import { useStyles } from './style'
import { Skeleton } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

export const ListSkeeleton = () => {
	const { classes } = useStyles()
	const largeScreen = useMediaQuery('(min-width: 800px)')

	return (
		<div className={classes.wrapper}>
			{Array.from({ length: 6 }).map((_, i) => (
				<Skeleton key={i} width='100%' height={largeScreen ? 300 : 150} />
			))}
		</div>
	)
}
