import { useStyles } from './style'
// import { LangSelect } from 'components/lang-select'
import { ReactComponent as IconLogo } from 'icons/logo.svg'

export const Header = () => {
	const { classes } = useStyles()

	return (
		<header className={classes.wrapper}>
			<div className={classes.wrapHeeader}>
				<IconLogo />
				{/* <div className={classes.wrapLang}>
					<LangSelect />
				</div> */}
			</div>
		</header>
	)
}
