import { Box, Skeleton } from '@mantine/core'
import { ListSkeeleton } from 'components/list-skeleton'

export const MainLoading = () => {
	return (
		<Box
			sx={{
				flexGrow: 1,
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<Skeleton height={450} />
			<Skeleton height={100} width={200} sx={{ margin: '20px 0' }} />
			<Skeleton height={10} width='65%' sx={{ margin: '10px 0' }} />
			<Skeleton height={10} width='75%' sx={{ margin: '10px 0' }} />
			<Skeleton height={10} width='70%' />
			<Skeleton height={10} width='33%' sx={{ margin: '40px 0 0' }} />
			<Skeleton height={300} width={300} circle sx={{ margin: '20px 0' }} />
			<ListSkeeleton />
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					padding: '10px 30px',
					justifyContent: 'space-between',
				}}
			>
				<Skeleton height={10} width={100} />
				<Skeleton height={10} width={100} />
				<Skeleton height={10} width={100} />
			</Box>
		</Box>
	)
}
