import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme, _params) => ({
	wrapper: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	wrapLang: {
		top: 0,
		right: 40,
		height: '100%',
		display: 'flex',
		position: 'absolute',
		alignItems: 'center',
		[`@media (max-width: ${theme.breakpoints.sm}px)`]: {
			position: 'static',
			right: 30,
		},
	},
	wrapHeeader: {
		height: 85,
		width: '100%',
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		justifyContent: 'center',
		[`@media (max-width: ${theme.breakpoints.sm}px)`]: {
			padding: '0 30px',
		},
	},
}))
