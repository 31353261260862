import { createStyles } from '@mantine/core'

export const useStyles = createStyles({
	wrapper: {
		userSelect: 'none',
		position: 'relative',
		img: {
			width: '100%',
			height: '100%',
			objectFit: 'contain',
		},
	},
	loader: {
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		zIndex: 1000,
		position: 'absolute',
	},
})
