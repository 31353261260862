import { createStyles } from '@mantine/core'

export const useStyles = createStyles({
	wrapper: {
		width: '100%',
		display: 'flex',
		overflow: 'hidden',
		minHeight: '100vh',
		flexDirection: 'column',
	},
	child: {
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
	},
})
