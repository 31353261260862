import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme, _params) => ({
	wrapImage: {
		width: '100%',
		height: 'fit-content',
	},
	wrapBrandLogo: {
		maxWidth: 750,
		display: 'grid',
		gridGap: '30px',
		padding: '30px 0',
		textAlign: 'center',
		justifyItems: 'center',
		gridTemplateColumns: '1fr',
		[`@media (max-width: ${theme.breakpoints.lg}px)`]: {
			gridGap: '20px',
		},
	},
	title: {
		fontSize: 18,
		fontWeight: 400,
		color: '#000000',
		lineHeight: '23px',
		textAlign: 'center',
		[`@media (max-width: ${theme.breakpoints.lg}px)`]: {
			fontSize: 16,
		},
	},
	highLight: {
		fontWeight: 600,
		color: '#000000',
	},
}))
