import dayjs from 'dayjs'
import { useEffect } from 'react'
import utc from 'dayjs/plugin/utc'
import isToday from 'dayjs/plugin/isToday'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useParamQuery } from 'hooks/useParamQuery'
import { getWebResultCndpSkin } from 'api/assessment'
import { showNotification } from '@mantine/notifications'

dayjs.extend(isToday)
dayjs.extend(utc)

export const useApp = () => {
	const { i18n } = useTranslation()
	const { params } = useParamQuery()
	const batchId = params.get('batch_id')
	const languageParam = params.get('language')
	const isthereBatchIdAndCustomerId = batchId !== null

	useEffect(() => {
		i18n.changeLanguage(languageParam ?? 'en')
	}, [])

	const { data = { secondsRemained: 120 * 60, isExpired: false }, isLoading } = useQuery(
		['web-result-cndpskin', batchId],
		async () => await getWebResultCndpSkin(String(batchId)),
		{
			select: data => {
				const date = data.data.body[0].date as string // 2023-03-16T00:00:00.000Z
				const time = data.data.body[0].time as string // 01:35:56.0262

				const dateToSplit = date.substring(0, 10) // 2023-03-16
				const timeToSplit = time.replace('.', ':') // 01:35:56:488375

				const [year, month, day] = dateToSplit.split('-').map(value => Number(value)) // [2023, 03, 16]
				const [hours, minutes, seconds] = timeToSplit.split(':').map(value => Number(value)) // [01, 35, 56]

				const analysisTime = new Date(year, month - 1, day, hours, minutes, seconds)
				// Get local date
				const ld = new Date()
				// Get UTC date relatively to local
				const currentTime = new Date(
					ld.getUTCFullYear(),
					ld.getMonth(),
					ld.getUTCDate(),
					ld.getUTCHours(),
					ld.getUTCMinutes(),
					ld.getUTCSeconds(),
				)

				// Get difference in milliseconds
				const timeDifference = currentTime.getTime() - analysisTime.getTime()

				// Format difference
				const secondsDifference = Math.round(timeDifference / 1000)

				// If difference is more than two hours
				const isExpired = secondsDifference > 120 * 60

				// Calculate how many seconds remained for showing page
				const secondsRemained = 120 * 60 - secondsDifference

				return { isExpired, secondsRemained }
			},

			onError: (err: any) => {
				showNotification({
					title: 'Error !!!',
					message: err.response.data.error,
				})
			},
			keepPreviousData: true,
			enabled: isthereBatchIdAndCustomerId,
		},
	)

	return { data, isLoading, isthereBatchIdAndCustomerId }
}
