import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme, _params, getRef) => ({
	wrapper: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	title: {
		fontSize: 20,
		marginTop: 20,
		fontWeight: 400,
		color: '#000000',
		lineHeight: '25px',
		textTransform: 'uppercase',
	},
	wrapList: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		gap: '25px',
		width: '100%',
		maxWidth: '1340px',
		padding: '20px 30px 60px',
		borderBottom: '1px solid #E5E5E5',
		[`@media (max-width: ${theme.breakpoints.sm - 1}px)`]: {
			gridTemplateColumns: '1fr 1fr',
		},
	},
	list: {
		h6: {
			fontSize: 16,
			marginTop: 8,
			fontWeight: 600,
			color: '#000000',
			padding: '0 10px',
			lineHeight: '20px',
			textAlign: 'center',
			[`@media (max-width: ${theme.breakpoints.sm}px)`]: {
				fontSize: 13,
			},
		},
		p: {
			fontSize: 16,
			margin: '8px 0',
			fontWeight: 400,
			color: '#000000',
			lineHeight: '20px',
			textAlign: 'center',
			padding: '0 10px',
			[`@media (max-width: ${theme.breakpoints.sm}px)`]: {
				fontSize: 13,
			},
		},
	},
}))
