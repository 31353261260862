import { useStyles } from './style'
import { List } from 'components/list'
import { useRoutine } from './useRoutine'
import { useTranslation } from 'react-i18next'

export const Routine = () => {
	const { t } = useTranslation()
	const { classes } = useStyles()
	const { data, isLoading } = useRoutine()

	return (
		<div className={classes.wrapper}>
			<h3 className={classes.title}>{t('your_beauty_routine')}</h3>
			<List list={data.skincare} title={t('common.skincare')} isLoading={isLoading} />
			<List list={data.makeup} title={t('common.makeup')} isLoading={isLoading} />
		</div>
	)
}
