import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme, _params, getRef) => ({
	wrapper: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	title: {
		fontSize: 14,
		fontWeight: 600,
		color: '#000000',
		lineHeight: '17px',
		textTransform: 'uppercase',
	},
	text: {
		fontSize: 14,
		fontWeight: 400,
		color: '#000000',
	},
	box1: {
		width: '100%',
		display: 'flex',
		padding: '10px 0',
		alignItems: 'center',
		background: '#F7F7F7',
		flexDirection: 'column',
		p: {
			fontSize: 12,
			width: '100%',
			fontWeight: 400,
			color: '#000000',
			lineHeight: '20px',
			textAlign: 'center',
		},
		h5: {
			fontSize: 12,
			marginTop: 10,
			fontWeight: 600,
			color: '#000000',
			lineHeight: '12px',
		},
	},
	box2: {
		height: 36,
		width: '100%',
		display: 'grid',
		alignItems: 'center',
		justifyItems: 'center',
		gridTemplateColumns: '1fr 1fr 1fr',
		h6: {
			fontSize: 14,
			fontWeight: 600,
			color: '#000000',
			lineHeight: '15px',
			textAlign: 'center',
			[`@media (max-width: ${theme.breakpoints.sm}px)`]: {
				fontSize: 12,
			},
		},
	},
}))
