import { App } from './app'
import { StrictMode } from 'react'
import { i18n } from './localization'
import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { MantineProvider } from '@mantine/core'
import { I18nextProvider } from 'react-i18next'
import { queryClient } from 'config/react-query'
import { QueryClientProvider } from '@tanstack/react-query'
import { NotificationsProvider } from '@mantine/notifications'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './index.css'

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
	<StrictMode>
		<QueryClientProvider client={queryClient}>
			<MantineProvider withGlobalStyles withNormalizeCSS>
				<I18nextProvider i18n={i18n}>
					<NotificationsProvider>
						<App />
					</NotificationsProvider>
				</I18nextProvider>
			</MantineProvider>
		</QueryClientProvider>
	</StrictMode>,
)

reportWebVitals()
