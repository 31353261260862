import { useParamQuery } from 'hooks/useParamQuery'
import { useStyles } from './style'
import { Image } from 'components/image'
import { ListSkeeleton } from 'components/list-skeleton'

interface IList {
	title: string
	isLoading: boolean
	list: Array<{
		id: string | number
		name: string
		collection: string
		routine: string
		link: string
		image_url: string
	}>
}

export const List = ({ list, title, isLoading }: IList) => {
	const { classes } = useStyles()
	const { params } = useParamQuery()
	const isActive = params.get('active') === 'yes'

	if (list.length === 0 && !isLoading) {
		return null
	}

	return (
		<div className={classes.wrapper}>
			<h5 className={classes.title}>{title}</h5>
			{isLoading ? (
				<ListSkeeleton />
			) : (
				<div className={classes.wrapList}>
					{list.map(v => {
						return isActive ? (
							<a key={v.id} target='_blank' href={v.link} rel='noreferrer'>
								<div className={classes.list}>
									<Image src={v.image_url} alt={v.name} />
									<h6>{v.collection}</h6>
									<p>{v.name}</p>
								</div>
							</a>
						) : (
							<div key={v.id} className={classes.list}>
								<Image src={v.image_url} alt={v.name} />
								<h6>{v.collection}</h6>
								<p>{v.name}</p>
							</div>
						)
					})}
				</div>
			)}
		</div>
	)
}
