import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
	wrapper: {
		gap: 50,
		width: '100%',
		display: 'grid',
		maxWidth: '1340px',
		padding: '10px 30px',
		gridTemplateColumns: '1fr 1fr 1fr',
		[`@media (max-width: ${theme.breakpoints.sm}px)`]: {
			gap: '20px 15px',
			gridTemplateColumns: '1fr 1fr',
		},
	},
}))
