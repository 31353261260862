import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme, _params, getRef) => ({
	wrapper: {
		flexGrow: 1,
		width: '100%',
		display: 'flex',
		padding: '80px 0',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		[`@media (max-width: ${theme.breakpoints.sm}px)`]: {
			padding: '40px 0',
		},
	},
	child: {
		width: '80%',
		flexGrow: 1,
		display: 'flex',
		borderRadius: 25,
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		backgroundColor: 'black',
		svg: {
			marginBottom: 20,
			[`@media (max-width: ${theme.breakpoints.sm}px)`]: {
				width: 120,
				height: 120,
			},
		},
		h1: {
			fontSize: 35,
			color: 'white',
			textAlign: 'center',
			[`@media (max-width: ${theme.breakpoints.sm}px)`]: {
				fontSize: 25,
			},
		},
	},
}))
