import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme, _params) => ({
	wrapper: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		borderTop: '1px solid #E5E5E5',
		borderBottom: '1px solid #E5E5E5',
	},
	title: {
		fontSize: 20,
		fontWeight: 600,
		paddingTop: 20,
		lineHeight: '25px',
	},
	chart: {
		position: 'relative',
		width: 1000,
		height: 700,

		'@media (max-width: 950px)': {
			width: 600,
			height: 350,
		},
	},
	score: {
		position: 'absolute',
		width: 50,
		height: 50,
		borderRadius: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: 600,

		'@media (max-width: 950px)': {
			width: 25,
			height: 25,
			fontSize: 10,
		},
	},
	label: {
		position: 'absolute',
		fontWeight: 600,
		display: 'flex',
		flexDirection: 'column',

		'@media (max-width: 950px)': {
			fontWeight: 500,
			fontSize: 9,
		},
	},
	hint: {
		fontWeight: 500,
		padding: '0px 5px',
		borderRadius: 4,
		maxWidth: 'max-content',

		'@media (max-width: 950px)': {
			fontSize: 9,
		},
	},

	score1: {
		top: 137,
		left: 610,

		'@media (max-width: 950px)': {
			top: 87,
			left: 340,
		},
	},
	label1: {
		top: 90,
		left: 670,

		'@media (max-width: 950px)': {
			maxWidth: 100,
			top: 50,
			left: 365,
		},
	},

	score5: {
		top: 395,
		left: 695,

		'@media (max-width: 950px)': {
			top: 190,
			left: 375,
		},
	},
	label5: {
		top: 400,
		left: 770,

		'@media (max-width: 950px)': {
			maxWidth: 75,
			top: 185,
			left: 405,
		},
	},

	score4: {
		top: '83%',
		left: '50%',
		transform: 'translate(-50%, -50%)',

		'@media (max-width: 950px)': {
			top: '76%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
		},
	},
	label4: {
		top: '91%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		textAlign: 'center',
		alignItems: 'center',

		'@media (max-width: 950px)': {
			top: '85%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
		},
	},

	score3: {
		top: 400,
		left: 255,

		'@media (max-width: 950px)': {
			top: 193,
			left: 198,
		},
	},
	label3: {
		top: 400,
		right: 770,
		textAlign: 'right',
		alignItems: 'flex-end',

		'@media (max-width: 950px)': {
			maxWidth: 70,
			top: 185,
			right: 405,
		},
	},

	score2: {
		top: 140,
		left: 335,

		'@media (max-width: 950px)': {
			top: 87,
			left: 228,
		},
	},
	label2: {
		top: 90,
		right: 670,
		textAlign: 'right',
		alignItems: 'flex-end',

		'@media (max-width: 950px)': {
			maxWidth: 120,
			top: 50,
			right: 355,
		},
	},
}))
