import { useStyles } from './style'
import { Trans } from 'react-i18next'

export const HeaderContent = () => {
	const { classes } = useStyles()

	return (
		<>
			<div className={classes.wrapBrandLogo}>
				<Trans
					i18nKey='results_skin_diagnosis_msg'
					components={{
						H2: <h2 className={classes.title} />,
						H3: <h3 className={classes.title} />,
					}}
				/>
			</div>
		</>
	)
}
