import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme, _params, getRef) => ({
	wrapper: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	title: {
		fontSize: 20,
		fontWeight: 600,
		color: '#000000',
		padding: '20px 0',
		lineHeight: '25px',
	},
}))
