import { useApp } from './useApp'
import { useStyles } from './style'
import { Footer } from 'components/footer'
import { Header } from 'components/header'
import { Expire } from 'components/expire'
import { Routine } from 'components/routine'
import { Assessment } from 'components/assessment'
import { MainLoading } from 'components/main-loading'
import { HeaderContent } from 'components/header-content'

export const App = () => {
	const { classes } = useStyles()
	const { data, isLoading, isthereBatchIdAndCustomerId } = useApp()

	return (
		<div className={classes.wrapper}>
			<Header />
			<div className={classes.child}>
				{isthereBatchIdAndCustomerId ? (
					<>
						{isLoading ? (
							<MainLoading />
						) : !data.isExpired ? (
							<>
								<HeaderContent />
								<Assessment />
								<Routine />
								<Footer secondsRemained={data.secondsRemained} />
							</>
						) : (
							<Expire />
						)}
					</>
				) : (
					<Expire />
				)}
			</div>
		</div>
	)
}
