import { useState } from 'react'
import { useStyles } from './style'
import { Skeleton } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { LazyLoadImage } from 'react-lazy-load-image-component'

interface IImage {
	src: string
	alt: string
}

export const Image = ({ src, alt }: IImage) => {
	const { classes } = useStyles()
	const [loading, setLoading] = useState(true)
	const largeScreen = useMediaQuery('(min-width: 800px)')
	const toggle = () => setLoading(prev => !prev)

	return (
		<div className={classes.wrapper}>
			{loading && (
				<Skeleton
					width='100%'
					className={classes.loader}
					height={largeScreen ? '300px' : '150px'}
				/>
			)}
			<LazyLoadImage
				alt={alt}
				src={src}
				width='100%'
				effect='blur'
				height='100%'
				afterLoad={toggle}
			/>
		</div>
	)
}
