import * as echarts from 'echarts/core'
import { useMediaQuery } from '@mantine/hooks'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useParamQuery } from 'hooks/useParamQuery'
import { getWebResultCndpSkin } from 'api/assessment'
import { showNotification } from '@mantine/notifications'

interface Result {
	analyzed_image_url: string
	avg_value: number
	date: string
	measurement: string
	original_image_url: string
	row_number: string
	time: string
	value: number
}

const TRANSLATIONS: { [key in string]: string } = {
	wrinkles: 'skin.analysisTypeWrinkles',
	dehydration: 'skin.analysisTypeHydration',
	porphyrin: 'skin.analysisTypeImpurity',
	pores: 'skin.analysisTypePores',
	spots: 'skin.analysisTypeSpots',
}

const ORDER = ['wrinkles', 'dehydration', 'porphyrin', 'pores', 'spots']

export const useAssessment = () => {
	const { t } = useTranslation()
	const { params } = useParamQuery()
	const batchId = params.get('batch_id')
	const largeScreen = useMediaQuery('(min-width: 950px)')

	const { data = { measurements: [] } } = useQuery(
		['web-result-cndpskin', batchId],
		async () => await getWebResultCndpSkin(String(batchId)),
		{
			select: data => {
				const results = data.data.body as Result[]
				const measurements = [] as any[]
				let hydration = 0

				results.forEach(result => {
					const measurement = result.measurement
					const title = t(TRANSLATIONS[measurement])
					const value = Math.floor(result.avg_value || result.value || 0)

					if (measurement === 'moistureT' || measurement === 'moistureU') {
						hydration += value
					} else if (measurement !== 'skintone') {
						measurements.push({ title, value, measurement })
					}
				})

				const dehydration = 99 - Math.floor(hydration / 2)

				measurements.push({
					title: t(TRANSLATIONS.dehydration),
					value: dehydration,
					measurement: 'dehydration',
				})

				const values = measurements.map(({ value }) => value)

				const sortedValues = values.sort((a, b) => a - b)

				const markedMeasurements = measurements.map(measurement => {
					const { value, ...rest } = measurement

					const minScore = sortedValues[0]
					const maxScore = sortedValues[sortedValues.length - 1]

					const sortedValuesWithoutMax = sortedValues.filter(
						sortedValue => sortedValue !== maxScore,
					)
					// Second lowest score (might be equal to lowest score)
					const avgScore = sortedValuesWithoutMax[sortedValuesWithoutMax.length - 1]

					let badge = ''

					if (minScore === 0 && minScore === maxScore) {
						badge = t('skin.strength')
					} else if (minScore > 0 && minScore === maxScore) {
						badge = t('skin.priority')
					} else if (value === minScore) {
						badge = t('skin.strength')
					} else if (value === maxScore || value === avgScore) {
						badge = t('skin.priority')
					}

					return {
						...rest,
						value,
						badge,
					}
				})

				const sortedMeasurements = ORDER.map(item =>
					markedMeasurements.find(({ measurement }) => measurement === item),
				)

				return { measurements: sortedMeasurements }
			},
			onError: (err: any) => {
				showNotification({
					title: 'Error !!!',
					message: err.response.data.error,
				})
			},
			keepPreviousData: true,
			enabled: !!batchId,
		},
	)

	const option = {
		color: ['#F8C2C0'],
		radar: [
			{
				indicator: data.measurements.map(() => ({ text: '', max: 100 })),
				center: ['50%', '50%'],
				radius: largeScreen ? 250 : 100,
				startAngle: 55,
				splitNumber: 6,
				shape: 'circle',
				axisName: {
					color: 'black',
					fontSize: largeScreen ? '17px' : '10px',
					formatter: () => '',
				},
				splitArea: {
					areaStyle: {
						color: [
							'rgba(255, 241, 235, 0.5)',
							'rgba(255, 241, 235, 0.5)',
							'rgba(255, 241, 235, 0.5)',
							'rgba(255, 241, 235, 0.5)',
							'rgba(255, 241, 235, 0.5)',
							'#F8DEDD',
						],
						shadowColor: 'rgba(0, 0, 0, 0)',
						shadowBlur: 10,
					},
				},
				axisLine: {
					lineStyle: {
						width: 2,
						color: '#FBE7DC',
					},
				},
				splitLine: {
					lineStyle: {
						width: 2,
						color: '#FBE7DC',
					},
				},
			},
		],
		series: [
			{
				symbol: 'none',
				type: 'radar',
				emphasis: {
					lineStyle: {
						width: 4,
					},
				},
				data: [
					{
						value: data.measurements.map(({ value }) => value),
						areaStyle: {
							color: new echarts.graphic.RadialGradient(0.5, 0.5, 0.9, [
								{
									color: 'rgba(248, 194, 192, 1)',
									offset: 0,
								},
								{
									color: 'rgba(252, 213, 209, 0.5)',
									offset: 0.8,
								},
							]),
						},
					},
				],
			},
		],
	}

	return { data, option }
}
